.logo {
  margin-bottom: 32px;
}

.row {
  background-image: url("../../../assets/background.png");
  min-height: 100vh;
}

.title {
  text-align: center;
  white-space: normal;
}
