.category {
  margin-bottom: 4px;
}

.disableLink {
  pointer-events: none;
}

.title {
  color: black !important;
  font-weight: normal !important;
  margin-bottom: 0;
}

.location {
  margin-right: 16px;
}

.image img {
  object-fit: cover;
  box-shadow: 1px 1px 8px #eaeaea36;
  border-radius: 2px;
  overflow: hidden;
}

.item {
  border-bottom: 0 !important;
  border-top-style: dashed;
  border-top-width: 2px;
  transition: background-color 0.5s ease;
}
.item:nth-child(7n + 1) {
  border-top-color: #ca6cc4;
}
.item:nth-child(7n + 1):hover {
  background-color: #ca6cc40a;
}
.item:nth-child(7n + 2) {
  border-top-color: #fe547b;
}
.item:nth-child(7n + 2):hover {
  background-color: #fe547b0a;
}
.item:nth-child(7n + 3) {
  border-top-color: #f97f48;
}
.item:nth-child(7n + 3):hover {
  background-color: #f97f480a;
}
.item:nth-child(7n + 4) {
  border-top-color: #f4b74a;
}
.item:nth-child(7n + 4):hover {
  background-color: #f4b74a0a;
}
.item:nth-child(7n + 5) {
  border-top-color: #7fc1c6;
}
.item:nth-child(7n + 5):hover {
  background-color: #7fc1c60a;
}
.item:nth-child(7n + 6) {
  border-top-color: #2588c9;
}
.item:nth-child(7n + 6):hover {
  background-color: #2588c90a;
}
.item:nth-child(7n + 7) {
  border-top-color: #adc965;
}
.item:nth-child(7n + 7):hover {
  background-color: #adc9650a;
}
