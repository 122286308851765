.agreed {
  padding-bottom: 16px;
  text-align: left;
}

.notice {
  text-align: left;
}

.redIcon {
  color: red;
}
