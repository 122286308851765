.container {
  font-family: "Museo Sans W01_300";
}

/* Contact */
.contactSection {
  color: white;
  background-color: #1d1d1b;
  height: 304px;
  padding: 32px;
}

/* Contact Links */
.contactLinksSection {
  padding-top: 16px;
}

.contactLinksSectionItem {
  color: white;
  font-family: "Museo Sans W01_700";
  font-size: 15px;
  padding: 8px 16px;
}

/* Contact Info */
.contactInfoSection {
  padding: 24px 16px 16px;
}

.contactInfoTitle {
  font-family: "Museo Sans W01_700";
  font-size: 20px;
  padding-bottom: 8px;
}

.contactInfoDetails {
  font-size: 15px;
  padding-bottom: 16px;
}

.contactInfoDetailsEmail {
  color: white;
  font-family: "Museo Sans W01_700";
}

.contactInfoSocial {
  height: 60px;
}

.contactInfoSocialItem {
  background-size: 84px;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-indent: -999em;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  margin-right: 10px;
}

.contactInfoSocialItemFacebook {
  background-image: url("data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2050%2050%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M17.5,1C8.4,1,1,8.4,1,17.5C1,26.6,8.4,34,17.5,34S34,26.6,34,17.5C34,8.4,26.6,1,17.5,1z%20M22.2,10.7c0,0-1.9,0-2.3,0c-0.4,0-0.9,0.2-0.9,1v2.1h3.2l-0.3,3.6H19c0,4.6,0,10.2,0,10.2h-4.2c0,0,0-5.6,0-10.2h-2v-3.6h2v-2.3c0-1.7,0.8-4.3,4.3-4.3h3.1V10.7z%22/%3E%3C/svg%3E");
}

.contactInfoSocialItemTwitter {
  background-image: url("data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2050%2050%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M17.5,1C8.4,1,1,8.4,1,17.5C1,26.6,8.4,34,17.5,34S34,26.6,34,17.5C34,8.4,26.6,1,17.5,1z%20M25.8,14.2v0.5c0,5.5-4.2,11.8-11.8,11.8c-2.3,0-4.5-0.7-6.4-1.9c0.3,0,0.7,0.1,1,0.1c1.9,0,3.7-0.7,5.2-1.8c-1.8,0-3.4-1.2-3.9-2.9c0.3,0,0.5,0.1,0.8,0.1c0.4,0,0.7-0.1,1.1-0.1c-1.9-0.4-3.3-2.1-3.3-4.1c0,0,0,0,0-0.1c0.6,0.3,1.2,0.5,1.9,0.5c-1.1-0.7-1.9-2-1.9-3.5c0-0.8,0.2-1.5,0.6-2.1c2.1,2.5,5.1,4.2,8.6,4.3c-0.1-0.3-0.1-0.6-0.1-0.9c0-2.3,1.9-4.2,4.2-4.2c1.2,0,2.3,0.5,3,1.3c0.9-0.2,1.8-0.5,2.6-1c-0.3,1-1,1.8-1.8,2.3c0.8-0.1,1.6-0.3,2.4-0.7C27.3,12.8,26.6,13.6,25.8,14.2z%22/%3E%3C/svg%3E");
}

.contactInfoSocialItemLinkedIn {
  background-image: url("data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2050%2050%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M17.5,0C7.8,0,0,7.8,0,17.5S7.8,35,17.5,35S35,27.2,35,17.5S27.2,0,17.5,0z%20M27.2,26c0,0.8-0.6,1.4-1.4,1.4H9.2c-0.8,0-1.4-0.6-1.4-1.4V9.2c0-0.8,0.6-1.4,1.4-1.4h16.6c0.8,0,1.4,0.6,1.4,1.4V26z%20M24.2,19v5.1h-2.9v-4.7c0-1.2-0.4-2-1.5-2c-0.8,0-1.3,0.6-1.5,1.1c-0.1,0.2-0.1,0.4-0.1,0.7v5h-2.9c0,0,0-8.1,0-8.9h2.9v1.3c0.4-0.6,1.1-1.5,2.7-1.5C22.8,15.1,24.3,16.4,24.2,19z%20M13.8,12.6c0,0.8-0.7,1.5-1.7,1.5s-1.6-0.6-1.6-1.5c0-0.8,0.7-1.5,1.7-1.5S13.8,11.7,13.8,12.6z%20M13.7,15.3v8.9h-3v-8.9H13.7z%22/%3E%3C/svg%3E");
}

.contactInfoSocialItemInstagram {
  background-image: url("data:image/svg+xml,%3C?xml%20version%3D%221.0%22%20?%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2050%2050%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M17.5,1C8.4,1,1,8.4,1,17.5S8.4,34,17.5,34S34,26.6,34,17.5S26.6,1,17.5,1z%20M28,22c0,3.3-2.7,6-6,6h-8.9c-3.3,0-6-2.7-6-6v-8.9c0-3.3,2.7-6,6-6H22c3.3,0,6,2.7,6,6V22z%20M22,9.1h-8.9c-2.2,0-4,1.8-4,4V22c0,2.2,1.8,4,4,4H22c2.2,0,4-1.8,4-4v-8.9C26,10.9,24.2,9.1,22,9.1z%20M17.5,23c-3,0-5.4-2.4-5.4-5.4s2.5-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4S20.5,23,17.5,23z%20M22.9,13.3c-0.7,0-1.2-0.5-1.2-1.2c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2C24.1,12.8,23.6,13.3,22.9,13.3z%20M20.9,17.6c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4c0.1-1.9,1.6-3.4,3.4-3.4C19.4,14.2,20.9,15.7,20.9,17.6z%22/%3E%3C/svg%3E");
}

/* Sponsors */
.sponsorsSection {
  height: 312px;
  padding: 16px;
}

.sponsorsSectionLogos {
  padding: 8px 16px 32px;
}

.sponsorsSectionLogo {
  width: 200px;
}

.sponsorsSectionCopyright {
  font-size: 12px;
  padding: 0 16px 16px;
}
