.card {
  border-radius: 8px;
  margin-top: 128px;
}

.name {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
