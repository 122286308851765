.carouselImage {
  height: 320px;
  object-fit: contain;
  width: 100%;
}

.deleteIcon {
  color: red !important;
}

.favourite {
  color: red !important;
}
