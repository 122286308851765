.container {
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.antMenuOverflow {
  justify-content: right !important;
  display: block !important;
}

.popupContainer {
  border: 0;
}

.container :global .ant-menu-item,
.container :global .ant-menu-submenu,
.popupContainer :global .ant-menu-item {
  font-family: "Johnston ITC W01 Medium";
}

.popupContainer :global .ant-menu-item {
  font-size: 18px;
}

.logoMenuItem {
  float: left;
  background-color: white !important;
  padding: 6px 16px 10px !important;
  position: absolute !important;
  left: 0 !important;
}

.logo {
  display: block;
  height: 60px;
}

.right {
  float: right;
}

.sos {
  background-color: #fc577d;
  float: right;
}

.donateButtonContainer {
  background-color: transparent !important;
  float: right;
}

.donateButtonContainer:hover {
  background-color: transparent !important;
}

.donateButtonLink {
  display: inline-block;
  background: #ffa500;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 42px;
  border-radius: 10px;
  margin-right: 0;
  font-family: "Johnston ITC W01 Medium";
  font-weight: normal;
  text-transform: none;
  color: rgb(51, 51, 51) !important;
  font-size: 19px;
  border: 1px solid transparent;
}

.floatingDonateButton {
  position: fixed;
  bottom: 30px;
  left: 20px;
}

.donateButtonLink:hover {
  color: rgb(51, 51, 51);
}
