.row {
  padding-top: 16px;
}

.card {
  text-align: center;
  font-family: "Johnston ITC W01 Bold";
}

.left {
  background-color: #f8cd83;
}

.right {
  background-color: #a7d4d8;
}

.bottom {
  background-color: #f5f6f7;
}

.title {
  padding-top: 32px;
  padding-bottom: 16px;
}

.paragraph {
  padding-bottom: 32px;
  font-size: 19px;
}
