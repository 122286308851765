.container {
  background-color: white;
  border-radius: 126px;
  border: 5px solid white;
  cursor: pointer;
  display: block;
  height: 128px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -64px;
  overflow: hidden;
  width: 128px;
}

.image {
  height: 128px;
  object-fit: cover;
  width: 128px;
}

.inner {
  height: 118px;
  text-align: center;
  width: 118px;
}

.plus {
  font-size: 48px;
  line-height: 128px;
}
