.cardEmail {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: dashed;
}

.cardMobile {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-style: none;
}

.code {
  width: auto !important;
  border-right: none;
}

.icon {
  background-color: #2cacee;
}

.login {
  margin-top: 16px;
}

.verify {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
